import { TRACKING_EVENTS } from '@/core/tracking/constants';

import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useCallback, useMemo } from 'react';

import { NavbarItem } from '@/app/navigation/components/Navbar/NavbarItem';
import { useGetWorkspacePathname } from '@/app/workspaces/hooks/useGetWorkspacePathname';
import { track } from '@/core/tracking';

type Props = {
    /**
     * Optional href to override the back button's Link href.
     * By default, the back button links to the "home" of the
     * active / last active workspace.
     */
    overrideBackLink?: string;
};

export const BackButton = ({ overrideBackLink }: Props) => {
    const { getHomeWorkspacePathname } = useGetWorkspacePathname();
    const backLink = useMemo(() => {
        return overrideBackLink ?? getHomeWorkspacePathname();
    }, [getHomeWorkspacePathname, overrideBackLink]);

    const trackClick = useCallback(() => {
        track(TRACKING_EVENTS.navigation.actions.backButtonClicked, {});
    }, []);

    return (
        <NavbarItem
            labelKey="back"
            href={backLink}
            onClick={trackClick}
            icon={<ArrowLeftIcon className="size-5" />}
            size="icon"
        />
    );
};
