import { cn } from '@/utils/cn';

import type { ReactNode } from 'react';

export interface Props {
    children?: ReactNode;
    showOutline?: boolean;
}

export const IconWrapper = ({ children, showOutline }: Props) => {
    return (
        <div
            className={cn(
                'flex size-8 items-center justify-center rounded-lg border bg-white text-gray-600 outline outline-[2px] outline-offset-1',
                {
                    'outline-transparent': !showOutline,
                    'outline-blue-600': showOutline,
                },
            )}
        >
            {children}
        </div>
    );
};
