import { hashString } from '@/utils/hashString';

export const getWorkspaceBackgroundColor = (workspaceName: string): string => {
    const WORKSPACE_ICON_BG_COLORS = [
        '#8077F1',
        '#6A85FF',
        '#38ABF1',
        '#16C3C4',
        '#58CAA0',
        '#F9BE34',
        '#FB8330',
        '#F3525A',
        '#F56FA6',
        '#C580E6',
        '#B4B9C8',
        '#070707',
    ];

    const hashValue = hashString(workspaceName);

    return WORKSPACE_ICON_BG_COLORS[Math.abs(hashValue) % WORKSPACE_ICON_BG_COLORS.length];
};
