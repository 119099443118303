import { MODAL_OPTIONS } from '@/app/modals/constants';
import { NAME } from '@/app/navigation/constants';
import { TRACKING_EVENTS } from '@/core/tracking/constants';

import { UserPlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { showModal } from '@/app/modals/models/modals';
import { Modals } from '@/app/modals/types';
import { useWorkspaces } from '@/app/workspaces/hooks/useWorkspaces';
import { useAppDispatch } from '@/core/redux/hooks';
import { track } from '@/core/tracking';
import { Button } from '@/ui/components/Button';
import { getIsWorkspaceEnv } from '@/utils/environments';

export const InviteButton = () => {
    const { t } = useTranslation(NAME);
    const { activeWorkspace } = useWorkspaces();
    const isDefaultWorkspace = activeWorkspace?.attributes?.default;
    const isWorkspaceEnv = getIsWorkspaceEnv();
    const dispatch = useAppDispatch();
    const sharedTrackingData = useMemo(() => {
        return {
            currentWorkspace: {
                default: isDefaultWorkspace,
                custom: !isDefaultWorkspace,
            },
            whiteLabelView: isWorkspaceEnv,
        };
    }, [isDefaultWorkspace, isWorkspaceEnv]);

    const handleClick = () => {
        track(TRACKING_EVENTS.navigation.actions.inviteClicked, sharedTrackingData);

        dispatch(showModal(Modals.INVITE_MEMBERS, {}, MODAL_OPTIONS[Modals.INVITE_MEMBERS]));
    };

    return (
        <Button
            onClick={handleClick}
            size="small"
            className="h-10 rounded-lg py-2 text-sm font-medium text-gray-500 hover:text-gray-800"
        >
            <UserPlusIcon className="size-5" />

            <span className="ml-2 max-lg:hidden">{t('invite')}</span>
        </Button>
    );
};
