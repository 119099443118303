import { WorkspaceSetting } from '../../types';

export const encodeUrlStringToWorkspaceSetting = (setting: string | string[]) => {
    if (setting === '') {
        return WorkspaceSetting.General;
    }

    if (Array.isArray(setting)) {
        return setting[0].toUpperCase() as WorkspaceSetting;
    }

    return setting.toUpperCase() as WorkspaceSetting;
};

export const decodeWorkspaceSettingToUrlString = (setting: WorkspaceSetting) => {
    return setting.toLowerCase();
};
