import { decodeWorkspaceSettingToUrlString } from '../components/WorkspaceSettings';
import { WorkspaceSetting } from '../types';

type WorkspaceLinks = {
    settings: Record<WorkspaceSetting, string>;
    general: Record<string, string>;
};

export const getWorkspaceLinks = (activeWorkspaceId: string): WorkspaceLinks => ({
    settings: {
        [WorkspaceSetting.General]: `/workspaces/${activeWorkspaceId}/settings`,
        [WorkspaceSetting.Icon]: `/workspaces/${activeWorkspaceId}/settings/${decodeWorkspaceSettingToUrlString(WorkspaceSetting.Icon)}`,
        [WorkspaceSetting.Members]: `/workspaces/${activeWorkspaceId}/settings/${decodeWorkspaceSettingToUrlString(WorkspaceSetting.Members)}`,
        [WorkspaceSetting.Preview]: `/workspaces/${activeWorkspaceId}/settings/${decodeWorkspaceSettingToUrlString(WorkspaceSetting.Preview)}`,
        [WorkspaceSetting.Funnels]: `/workspaces/${activeWorkspaceId}/settings/${decodeWorkspaceSettingToUrlString(WorkspaceSetting.Funnels)}`,
        [WorkspaceSetting.DeleteWorkspace]: `/workspaces/${activeWorkspaceId}/settings/${decodeWorkspaceSettingToUrlString(WorkspaceSetting.DeleteWorkspace)}`,
        [WorkspaceSetting.StatusTemplates]: `/workspaces/${activeWorkspaceId}/settings/${decodeWorkspaceSettingToUrlString(WorkspaceSetting.StatusTemplates)}`,
        [WorkspaceSetting.SenderSettings]: `/workspaces/${activeWorkspaceId}/settings/${decodeWorkspaceSettingToUrlString(WorkspaceSetting.SenderSettings)}`,
    },
    general: {
        dashboard: `/workspaces/${activeWorkspaceId}/dashboard`,
        allWorkspaces: '/workspaces',
        workspace: `/workspace/${activeWorkspaceId}`,
        newWorkspace: '/workspaces/new',
    },
});
