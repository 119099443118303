import { WS_LOGO_MAX_RENDERED_SIZE } from '@/app/workspaces/constants';

import Image from 'next/image';
import qs from 'query-string';
import { useMemo } from 'react';

import { cn } from '@/utils/cn';

export interface Props {
    name: string;
    logoSrc?: string;
    active?: boolean;
    className?: string;
    bgColor?: string;
    size?: 'sm' | 'md' | 'lg';
    hideBorder?: boolean;
}

export const WorkspaceIcon = ({
    active,
    name,
    logoSrc,
    className,
    bgColor,
    size,
    hideBorder,
}: Props) => {
    const logoWithParams = useMemo<string | null>(() => {
        if (logoSrc) {
            return `${logoSrc}?${qs.stringify({ w: WS_LOGO_MAX_RENDERED_SIZE, fit: 'clip', auto: 'format,compress' })}`;
        }

        return null;
    }, [logoSrc]);

    return (
        <div
            className={cn(
                'flex size-8 shrink-0 items-center justify-center overflow-hidden rounded-md text-white shadow-sm outline outline-[2px] outline-offset-1 hover:shadow',
                {
                    border: !hideBorder,
                    'outline-transparent': !active,
                    'outline-blue-600': active,
                    'size-16 rounded-xl': size === 'md',
                    'size-20 rounded-2xl': size === 'lg',
                },
                className,
            )}
            style={{ backgroundColor: logoWithParams ? 'white' : bgColor }}
        >
            {logoWithParams ? (
                <Image
                    draggable={false}
                    src={logoWithParams}
                    alt={name}
                    width={size === 'lg' ? 80 : size === 'md' ? 64 : 40}
                    height={size === 'lg' ? 80 : size === 'md' ? 64 : 40}
                    aria-hidden
                    className="size-full object-contain"
                />
            ) : (
                <p
                    className={cn('flex size-full items-center justify-center font-semibold', {
                        'text-2xl': size === 'md',
                        'text-3xl': size === 'lg',
                    })}
                >
                    {name?.slice(0, 1).toUpperCase()}
                </p>
            )}
        </div>
    );
};
